import React from "react";
import './BlurItem.css'

function BlurItem() {
  return (
    <div className="blur-item" title="Content will be shown when template is downloaded" >
    </div>
  );
}

export default BlurItem;
